import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import slug from 'slug';
import _ from 'lodash';
import Layout from '../components/layout';

const Service = (props) => {
  const {
    data: {
      servicesJson: { name, description, image },
      allServicesJson: { edges: services },
    },
  } = props;

  return (
    <Layout>
      <div>
        <section className="breadcrumb-area">
          <div className="container text-center">
            <h1>{name}</h1>
          </div>
        </section>
        {/* End breadcrumb area */}
        {/* Start breadcrumb bottom area */}
        <section className="breadcrumb-botton-area">
          <div className="container">
            <div className="left pull-left">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><i className="fa fa-caret-right" aria-hidden="true" /></li>
                <li><Link to="/services">Services</Link></li>
                <li><i className="fa fa-caret-right" aria-hidden="true" /></li>
                <li>{name}</li>
              </ul>
            </div>
            <div className="right pull-right">
              {/* <a href="#">
                <i className="fa fa-share-alt" aria-hidden="true" />
Share
              </a> */}
            </div>
          </div>
        </section>
        {/* End breadcrumb bottom area */}
        {/* Start service single area */}
        <section id="service-single-area" className="smartphone-repair-area">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-sm-12 col-xs-12 pull-right">
                <div className="service-single-content">
                  <div className="row top-content">
                    <div className="col-md-5 col-sm-12 col-xs-12">
                      <div className="img-holder">
                        <Img
                          src={image.childImageSharp.sizes.src}
                          sizes={image.childImageSharp.sizes}
                          alt={name}
                        />
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-12 col-xs-12">
                      <div className="text-holder">
                        <div className="title">
                          <h2>{name}</h2>
                        </div>
                        <div className="text" dangerouslySetInnerHTML={{ __html: description }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-7 col-xs-12 pull-left">
                <div className="service-single-sidebar">
                  <ul className="service-lists">
                    <li className="allservice">
                      <Link to="/services">
View All Services
                        <i className="fa fa-cog" aria-hidden="true" />
                      </Link>

                    </li>
                    {
                       services.map(service => <li><Link to={`/services/${slug(_.lowerCase(service.node.name))}`}>{service.node.name}</Link></li>)
                    }
                  </ul>
                  <div className="repair-time">
                    <h3>
Repair Less Than
                      <br />
                      {' '}
60 Minutes!
                    </h3>
                    <Link className="thm-btn bg-1" to="/contact">Make Appoinment</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

Service.propTypes = {
  data: PropTypes.shape(PropTypes.object),
};
Service.defaultProps = {
  data: {},
};
export default Service;

export const pageQuery = graphql`
  query($name: String) {
    allServicesJson{
      edges{
        node{
           name
        }
      }
    }
    servicesJson(name: {eq: $name }){
          name
          description
          image{
            childImageSharp{
              id
              sizes( maxWidth: 800 ) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
    }
`;
